import React, { useState, useEffect, useContext } from 'react'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import './App.css';

import ChatProvider from "./context/ChatContext"
import ChatWindow from './pages/ChatWindow'

// import io from "socket.io-client";

const SocketContext = React.createContext();


export function useSocketContext() {
  return useContext(SocketContext)
}


function App() {

  // const [sock, setSock] = useState();
  // useEffect(() => {
  //   const socket = io('http://165.22.12.72:5150/', {
  //     transports: ["websocket", "polling"], // use WebSocket first, if available
  //     auth: {
  //       token: 'pj'
  //     }
      
  //   });
  // setSock(socket);


  //  socket.on("connect", () => {
  //     // setConnection(true);
  //     console.log('Connected');
  //     // jRoom();
  //   });

  //  socket.on("message", (args) => {
  //     // setConnection(true);
  //     console.log('coonect',args);
  //     // jRoom();
  //   });

    // socket.on("connect", () => {
    //   // setConnection(true);
      
    //   console.log('conx connect', socket)
    //   socketContext?.emit("joinRoom", { username, roomname });
    //   //       const userId = await fetchUserId(socket);
    //   // console.log(userId)
      

    //   // socket.on("message", (args) => {
    //   //   // ...
    //   //   console.log(args,"on-msg")
    //   // });
    // });
  


  // }, []);

  return (

    <SocketContext.Provider value={null}>
      <Router>
        <Switch> <ChatProvider>
          <Route path="/" exact>
            <ChatWindow />
          </Route>
        </ChatProvider>
        </Switch>

      </Router>
    </SocketContext.Provider>

  );
}

export default App;

import { LocalGasStationRounded } from '@material-ui/icons'
import React, {useEffect} from 'react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'



const Register = (props) => {

    const MySwal = withReactContent(Swal)

    useEffect(() => {
        console.log(props, "props")
        props.reg(6)
        doRegister(props);
    }, [])

    const doRegister = async(p) => {
        const { value: formValues } = await Swal.fire({
            title: 'Register Account',
            html:
              '<input id="username" class="swal2-input" placeholder="Enter a screenname">' + '',
            //   '<input type="email" id="email" class="swal2-input" placeholder="Enter your email address">',
            focusConfirm: false,
            showCancelButton: true,
            allowOutsideClick: false,
            preConfirm: () => {
              return [
                document.getElementById('username').value,
                // document.getElementById('email').value
              ]
            }
          })
          
          if (formValues) {
            // Swal.fire(JSON.stringify(formValues))
            console.log(p,"---")
            p.reg({
                registered: true,
                value: formValues
            })
            
          } else {
                        const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer)
                  toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
              })
              
              Toast.fire({
                icon: 'error',
                title: 'Need an account to continue'
              })

              p.reg({
                registered: false,
                value: null
            })
          }
    }

    return (
        <div>
   
        </div>
    )
}

export default Register;
import React, { Component, useEffect, useState } from 'react';
import Chat from '../components/Chat'
// const io = require("socket.io")
import io from "socket.io-client";


// socket.on("connect", () => {
//     socket.emit("joinRoom", { uname:'pj', room:'room1' });
// });


function ChatWindow(){

//     const [sock, setSock] = useState();
//     useEffect(() => {
//         const socket = io('http://165.22.12.72:5150/', {
//   transports: ["websocket", "polling"], // use WebSocket first, if available
//   auth:{
//       token:'pj'
//   }
// });
        // setSock(socket);       
//         const socket = io("https://example.com", {
//   transports: ["websocket", "polling"] // use WebSocket first, if available
// });

// socket.on("connect_error", () => {
//   // revert to classic upgrade
//   socket.io.opts.transports = ["polling", "websocket"];
// });



        // io.on('connection', socket => {
        //     socket.emit('request', /* … */); // emit an event to the socket
        //     io.emit('broadcast', /* … */); // emit an event to all connected sockets
        //     socket.on('reply', () => { /* … */ }); // listen to the event
        //   });
        // console.log(socket)
        // const socket = io({
        //     auth: {
        //       token: "client",
              
        //     }
        //   });  
    // }, [])

    // useEffect(()=>{
    //     sock?.emit("joinRoom", { username:'pj', roomname:'room1' });
    // },[sock])

    return (
        <div style={{ flexGrow: 1,
            padding: 6,
            backgroundColor:"#eee",
            maxWidth:'100%'}}>
        <Chat/>
        </div>
    )
}
export default ChatWindow;